document.addEventListener('DOMContentLoaded', () => {
    setEqualHeights('.services-equal-heights');
});

function setEqualHeights(selector) {
    const container = document.querySelectorAll(selector);
    const maxHeight = Array.from(container).reduce((max, item) => {
        return Math.max(max, item.offsetHeight);
    }, 0);
    container.forEach(item => item.style.minHeight = `${maxHeight}px`);
}

import "./scss/style.scss"; // ./build/style-index.css
import "./scss/login/login-screen.scss"; // ./build/index.css

// JS Module
import "./js/swipers";
import "./js/contactPageMap";
import "./js/equalHeights";


// JS Classes
import MobileMenu from "./js/mobileMenu";
const mobileMenu = new MobileMenu();
import MegaMenuHandler from "./js/megaMenus";
const megaMenus = new MegaMenuHandler();

// Bootstrap
import { Collapse } from "bootstrap";
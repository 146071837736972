import hoverintent from 'hoverintent';

class MegaMenuHandler {
    constructor() {
        this.subMenuHovered = false;
        this.closeTimeout = null;

        document.addEventListener('DOMContentLoaded', () => {
           const megaMenu = document.querySelectorAll('.mega-menu');
           const subMenus = document.querySelectorAll('[data-menu-sub]');

           megaMenu.forEach((item, index) => {
              this.handleSubMenu(item, subMenus[index]);
           });

           window.addEventListener('resize', this.checkResizeCloseMenu.bind(this));
        });
    }

    handleSubMenu(btnEle, menuEle) {
        hoverintent(
            btnEle,
            () => {
            if(window.matchMedia('(min-width: 992px)').matches) {
                menuEle.classList.add('open');
            }
        }, () => {
            if(window.matchMedia('(min-width: 992px)').matches && !this.subMenuHovered) {
                this.closeTimeout = setTimeout(() => {
                    if(!this.subMenuHovered) {
                        menuEle.classList.remove('open');
                    }
                }, 300);
            }
        });

        menuEle.addEventListener('mouseenter', () => {
            this.subMenuHovered = true;
        });

        menuEle.addEventListener('mouseleave', () => {
            this.subMenuHovered = false;
            if (window.matchMedia('(min-width: 992px)').matches) {
                clearTimeout(this.closeTimeout);
                menuEle.classList.remove('open');
            }
        });

        document.addEventListener('click', (event) => {
            if (window.matchMedia('(min-width: 992px)').matches) {
                if (!btnEle.contains(event.target) && !menuEle.contains(event.target)) {
                    menuEle.classList.remove('open');
                }
            }
        });
    }

    checkResizeCloseMenu(e) {
        if (window.matchMedia('(max-width: 991px)').matches) {
            const megaMenus = document.querySelectorAll('.mega-menu');
            const subMenus = document.querySelectorAll('[data-menu-sub]');
            megaMenus.forEach((item, index) => {
                subMenus[index].classList.remove('open');
            });
        }
    }
}

export default MegaMenuHandler;
// import Swiper JS
import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
    const brandsBannerSwiper = new Swiper(".brands-banner__swiper", {
        modules: [ Autoplay ],
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        }
    });

    const testimonialCarouselSwiper = new Swiper(".testimonial-carousel__swiper", {
        modules: [ Navigation ],
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
            nextEl: '.testimonial-carousel__next',
            prevEl: '.testimonial-carousel__prev',
        },
    });
});
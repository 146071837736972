class MobileMenu {
    constructor() {
        this.navEl = document.querySelector('.site-header__nav');
        this.hamburger = document.querySelector('.hamburger');
        this.hasSubMenu = document.querySelectorAll('.menu-item-has-children');
        this.subMenuItems = document.querySelectorAll('.menu-item-has-children .sub-menu li');

        this.events();
        window.addEventListener("resize", this.checkResizeCloseMenu);
    }

    events() {
        document.addEventListener('DOMContentLoaded', () => {
            this.hamburger.addEventListener('click', this.handleClick.bind(this));
            this.hasSubMenu.forEach((item) => {
                item.addEventListener('click', (e) => {
                    if (window.matchMedia('(max-width: 991px)').matches) {
                        e.preventDefault();
                        item.querySelector('.sub-menu').classList.toggle('show');
                    }
                });
            });

            this.subMenuItems.forEach((item) => {
                item.addEventListener('click', (e) => {
                    e.stopPropagation();
                });
            });
        });
    }

    handleClick() {
        if (window.matchMedia('(max-width: 991px)').matches) {
            this.hamburger.classList.toggle('open');
            this.navEl.classList.toggle('open');

            if (this.navEl.classList.contains('open')) {
                document.body.classList.add('menu-open');
            } else {
                document.body.classList.remove('menu-open');
            }
        }
    }

    checkResizeCloseMenu(e) {
        if (window.matchMedia('(min-width: 992px)').matches) {
            if(this.hamburger || this.navEl) {
                this.hamburger.classList.remove('open');
                this.navEl.classList.remove('open');
            }
            document.body.classList.remove('menu-open');
            const openSubMenus = document.querySelectorAll('.sub-menu.show');
            openSubMenus.forEach((item) => {
                item.classList.remove('show');
            });
        }
    }
}

export default MobileMenu;
class CustomMap {
    constructor(elementId) {
        this.mapElement = document.getElementById(elementId);
        this.map = this.initMap();
    }

    initMap() {
        const markers = this.mapElement.querySelectorAll('.marker');
        const mapArgs = {
            zoom: parseFloat(this.mapElement.dataset.zoom) || 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        const map = new google.maps.Map(this.mapElement, mapArgs);
        map.markers = [];

        markers.forEach(marker => {
            this.initMarker(marker, map);
        });

        this.centerMap(map);

        return map;
    }

    initMarker(marker, map) {
        const lat = marker.dataset.lat;
        const lng = marker.dataset.lng;
        const latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
        };

        const newMarker = new google.maps.Marker({
            position: latLng,
            map: map
        });

        map.markers.push(newMarker);

        if (marker.innerHTML) {
            const infowindow = new google.maps.InfoWindow({
                content: marker.innerHTML
            });

            google.maps.event.addListener(newMarker, 'click', () => {
                infowindow.open(map, newMarker);
            });
        }
    }

    centerMap(map) {
        if (map.markers.length === 1) {
            const latLng = new google.maps.LatLng(
                map.markers[0].position.lat(),
                map.markers[0].position.lng()
            );
            map.setCenter(latLng);
            map.setZoom(16);
        } else {
            const bounds = new google.maps.LatLngBounds();

            map.markers.forEach(marker => {
                bounds.extend(marker.position);
            });

            map.fitBounds(bounds);
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const body = document.body;

    if (body.classList.contains('page-id-29')) {
        const map = new CustomMap('acf-map');
    }
});
